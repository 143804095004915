import { EmployeeOutput, EmployerOutput, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import {
  ActionIcon,
  Button,
  Card,
  Dropdown,
  MoreDotsIcon,
  Stack,
  TAccessOperation,
  useAccessScopeValidator,
  useDisclosure,
} from "@budgeinc/budge-ui-core";
import { Link } from "components/Link";

import { employeeEntityRoute } from "common/routes";

import { EMPLOYEE_TAB_KEYS } from "pages/Employees/EmployeeEntity";
import { isEmployeeMissingData } from "../utils";

import MissingDataTag from "./MissingDataTag";
import ReEngageCustomerButton from "./ReEngageCustomerButton";
import ImpersonateCustomerButton from "./ImpersonateCustomerButton";

interface OwnProps {
  employee: EmployeeOutput;
  employer: EmployerOutput;
}

const ActionCell = ({ employee, employer }: OwnProps) => {
  const disclosure = useDisclosure();
  const { validateAccessScopes } = useAccessScopeValidator();

  return (
    <Stack.Horizontal alignItems="center">
      {isEmployeeMissingData(employee) && <MissingDataTag />}
      <Link to={employeeEntityRoute(employer.id, employee.id, EMPLOYEE_TAB_KEYS.DETAILS)} asChild>
        <Button title="View" size="xs" color="primary" variant="gradient" />
      </Link>
      <Dropdown
        isOpen={disclosure.isOpen}
        onOpenChange={disclosure.toggle}
        anchor={<ActionIcon icon={MoreDotsIcon} color="primary" variant="light" size="xs" onPress={disclosure.open} />}
        tooltipValue="More"
        placement="end"
        overlay={
          <Card shadow="2xl">
            <Stack>
              <ReEngageCustomerButton onPress={disclosure.toggle} employee={employee} />
              {validateAccessScopes(TAccessOperation.WRITE, [UserWhoamiOutputScopesEnum.ClientsImpersonate]) && (
                <ImpersonateCustomerButton onPress={disclosure.toggle} employee={employee} employer={employer} />
              )}
            </Stack>
          </Card>
        }
      />
    </Stack.Horizontal>
  );
};

export default ActionCell;
