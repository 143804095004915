import React from "react";
import {
  Box,
  BuildValidationSchema,
  Button,
  EmailSchema,
  FormItem,
  FormProvider,
  Input,
  Modal,
  toast,
  useDisclosure,
  yup,
} from "@budgeinc/budge-ui-core";
import { useFormik } from "formik";
import { useBudgeMutation } from "api/useBudgeMutation";
import { invitesCrossTenantApi } from "api/BudgeApi";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { InviteSendInput } from "@budgeinc/budge-api";

enum Fields {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
}

const validationSchema = BuildValidationSchema({
  [Fields.EMAIL]: EmailSchema().required(),
  [Fields.FIRST_NAME]: yup.string().required(),
  [Fields.LAST_NAME]: yup.string().required(),
});

const InviteCustomerButton = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const disclosure = useDisclosure();
  const inviteCustomer = useBudgeMutation({
    mutationFn: async (input: InviteSendInput) => {
      const response = await invitesCrossTenantApi.sendInvite(employer?.id!, input);
      return response.data;
    },
    onSuccess: () => {
      disclosure.close();
      toast.success({
        title: "Success",
        message: "Invite sent successfully",
      });
    },
  });

  const form = useFormik({
    initialValues: {
      [Fields.EMAIL]: "",
      [Fields.FIRST_NAME]: "",
      [Fields.LAST_NAME]: "",
    },
    validationSchema,
    onSubmit: values =>
      inviteCustomer.mutate({
        firstName: values[Fields.FIRST_NAME],
        lastName: values[Fields.LAST_NAME],
        email: values[Fields.EMAIL],
      }),
  });

  return (
    <>
      <Button title="Invite Customer" size="sm" color="primary" variant="gradient" onPress={disclosure.open} />
      <Modal
        isOpen={disclosure.isOpen}
        onClose={disclosure.close}
        onClosed={() => {
          form.resetForm();
        }}
        header={{
          title: "Invite Customer",
        }}
        footer={{
          okButtonProps: {
            title: "Invite",
            variant: "gradient",
            onPress: form.submitForm,
            loading: inviteCustomer.isPending,
          },
        }}
      >
        <Box px="xl">
          <FormProvider value={form} formErrorMsg={inviteCustomer.errorMessage}>
            <FormItem name={Fields.FIRST_NAME}>
              <Input label="First Name" />
            </FormItem>
            <FormItem name={Fields.LAST_NAME}>
              <Input label="Last Name" />
            </FormItem>
            <FormItem name={Fields.EMAIL}>
              <Input label="Email Address" />
            </FormItem>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
};

export default InviteCustomerButton;
