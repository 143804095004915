import React from "react";
import {
  FinancialAccountOutput,
  FinancialAccountOutputAccountSourceEnum,
  FinancialAccountOutputAccountStatusEnum,
} from "@budgeinc/budge-api";
import { formatMoney } from "@budgeinc/budge-ui-utils";
import {
  Stack,
  Text,
  Tag,
  SuccessIcon,
  MoreDotsIcon,
  Divider,
  formatPercent,
  Description,
  EMPTY_VALUE_PLACEHOLDER,
  Collapse,
  Row,
  Col,
  i18n,
  Box,
} from "@budgeinc/budge-ui-core";
import { useAccountEntityContext } from "features/accounts/contexts/AccountEntityContext";
import ShowRawDataButton from "components/ShowRawDataButton";
import DateDisplay from "components/DateDisplay";
import { formatEnumValue } from "utils/format";
import AggregatorDataUpdatesIgnoredSwitch from "../AggregatorDataUpdatesIgnoredSwitch";
import MinimumPaymentIngestionBehaviorSelect from "../MinimumPaymentIngestionBehaviorSelect";

const GeneralInfoCard = () => {
  const {
    state: { account },
    dispatch,
  } = useAccountEntityContext();

  if (!account) return null;

  const isManualProv = account.accountSource === FinancialAccountOutputAccountSourceEnum.ManualProvisioning;
  const rawData: FinancialAccountOutput | undefined = account
    ? {
        ...account,
        institutionLogo: account.institutionLogo ? `${(account.institutionLogo || "").substring(0, 50)}...` : "",
      }
    : undefined;

  return (
    <>
      <Collapse
        trigger={
          <Stack.Horizontal alignItems="center" justifyContent="space-between" f={1}>
            <Stack.Horizontal>
              <Text fw="500" variant="bodyMedium">
                General Information
              </Text>
              {account.isFundingSource && <Tag value="Funding Source" color="green" />}
              {getAccountStatusTag(account.accountStatus)}
            </Stack.Horizontal>
            <ShowRawDataButton
              title="View raw data"
              size="xs"
              alignSelf="flex-end"
              modalTitle="Account Raw Data"
              data={rawData}
            />
          </Stack.Horizontal>
        }
        defaultOpen
      >
        <Row>
          <Col>
            <Description>
              <Description.Item label="ID">
                <Text>{account.id}</Text>
              </Description.Item>
              <Description.Item label="Slug">
                <Text>{account.slug}</Text>
              </Description.Item>
              <Description.Item label="Mask">
                <Text>{account.mask || EMPTY_VALUE_PLACEHOLDER}</Text>
              </Description.Item>
              <>
                {isManualProv && (
                  <Description.Item label="Router Number">
                    <Text>{account.routingNumber || EMPTY_VALUE_PLACEHOLDER}</Text>
                  </Description.Item>
                )}
                {isManualProv && (
                  <Description.Item label="Account Number">
                    <Text>{account.accountNumber || EMPTY_VALUE_PLACEHOLDER}</Text>
                  </Description.Item>
                )}
              </>
              <Description.Item label="Type">
                <Text>{account.accountType}</Text>
              </Description.Item>
              <Description.Item label="Source">
                <Text>{account.accountSource}</Text>
              </Description.Item>
              <Description.Item label="Data Source">
                <Text>{account.accountDataSource || EMPTY_VALUE_PLACEHOLDER}</Text>
              </Description.Item>
            </Description>
          </Col>
          <Col>
            <Description>
              <Description.Item label="Status">
                <Text>{account.accountStatus}</Text>
              </Description.Item>
              <Description.Item label="Is Plaid Linkable">
                <Text>{account.isPlaidLinkable ? "True" : "False"}</Text>
              </Description.Item>
              <Description.Item label="Is Plaid Linked">
                <Text>{account.isPlaidLinked ? "True" : "False"}</Text>
              </Description.Item>
            </Description>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col>
            <Description>
              <Description.Item label="Balance As Of">
                <DateDisplay numberOfLines={1} value={account.balanceAsOfDate} showTime />
              </Description.Item>
              <Description.Item label="Balance Available">
                <Text>
                  {formatMoney({
                    amountCents: account.balanceAvailableCents,
                  })}
                </Text>
              </Description.Item>
              <Description.Item label="Balance Current">
                <Text>
                  {formatMoney({
                    amountCents: account.balanceCurrentCents,
                  })}
                </Text>
              </Description.Item>
              <Description.Item label="Previous Balance">
                <Text>
                  {account.prevBalanceCurrentCents
                    ? formatMoney({
                        amountCents: account.prevBalanceCurrentCents,
                      })
                    : EMPTY_VALUE_PLACEHOLDER}
                </Text>
              </Description.Item>
              <Description.Item label="Previous Interest Rate">
                <Text>
                  {account.prevInterestRate
                    ? formatPercent({
                        value: account.prevInterestRate,
                      })
                    : EMPTY_VALUE_PLACEHOLDER}
                </Text>
              </Description.Item>
              <Description.Item label="Previous Minimum Payment">
                <Text>
                  {account.prevLoanMinimumPaymentAmountCents
                    ? formatMoney({
                        amountCents: account.prevLoanMinimumPaymentAmountCents,
                      })
                    : EMPTY_VALUE_PLACEHOLDER}
                </Text>
              </Description.Item>
              <Description.Item label="Credit Limit">
                {account.creditLimitCents
                  ? formatMoney({
                      amountCents: account.creditLimitCents,
                    })
                  : EMPTY_VALUE_PLACEHOLDER}
              </Description.Item>
              <Description.Item label="Lifetime Starting Balance">
                <Text>
                  {formatMoney({
                    amountCents: account.lifetimeStartingBalance,
                  })}
                </Text>
              </Description.Item>
              <Description.Item label="Lifetime Minimum Balance">
                <Text>
                  {formatMoney({
                    amountCents: account.lifetimeMinimumBalance,
                  })}
                </Text>
              </Description.Item>
              <Description.Item label="Has Subscribed Payment Reminders">
                {account.hasSubscribedPaymentReminders ? "True" : "False"}
              </Description.Item>
            </Description>
          </Col>
          <Col>
            <Description>
              <Description.Item label="Interest Rate">
                <Text>
                  {formatPercent({
                    value: account.interestRate || 0,
                  })}
                </Text>
              </Description.Item>
              <Description.Item label="Estimated Interest Rate">
                <Text>
                  {formatPercent({
                    value: account.estimatedInterestRate || 0,
                  })}
                </Text>
              </Description.Item>
              <Description.Item label="Estimated Minimum Payment">
                <Text>
                  {formatMoney({
                    amountCents: account.estimatedMinimumPaymentAmountCents || 0,
                  })}
                </Text>
              </Description.Item>
              <Description.Item label="Last Payment">
                <Text>
                  {formatMoney({
                    amountCents: account.loanLastPaymentCents,
                  })}
                </Text>
              </Description.Item>
              <Description.Item label="Min. Payment Status">
                <Text>{account.minimumPaymentAccountStatus}</Text>
              </Description.Item>
              <Description.Item label="Expected Payoff Date">
                <DateDisplay numberOfLines={1} value={account.loanExpectedPayoffDate} showTime />
              </Description.Item>
              <Box mt="sm" maw={350}>
                <MinimumPaymentIngestionBehaviorSelect
                  account={account}
                  onAccountChange={updatedAccount => dispatch({ type: "set", data: updatedAccount })}
                />
              </Box>
            </Description>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col>
            <Description.Item label="Last Statement Issue Date">
              {account.lastStatementIssueDate ? (
                <DateDisplay value={account.lastStatementIssueDate} />
              ) : (
                EMPTY_VALUE_PLACEHOLDER
              )}
            </Description.Item>
            <Description.Item label="Last Statement Balance">
              <Text>
                {formatMoney({
                  amountCents: account.lastStatementBalanceAmountCents,
                })}
              </Text>
            </Description.Item>
            <Description.Item label="Last Statement Minimum Payment">
              <Text>
                {formatMoney({
                  amountCents: account.lastStatementMinimumPaymentAmountCents,
                })}
              </Text>
            </Description.Item>
            <Description.Item label="Last Statement Due Date">
              {account.lastStatementNextPaymentDueDate ? (
                <DateDisplay value={account.lastStatementNextPaymentDueDate} showTime />
              ) : (
                EMPTY_VALUE_PLACEHOLDER
              )}
            </Description.Item>
            <Description.Item label="Last Statement Minimum Payment Is Due">
              <Text>{account.lastStatementMinimumPaymentAmountIsDue ? "True" : "False"}</Text>
            </Description.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col>
            <Description>
              <Description.Item label="Debt Plan Seed Balance">
                <Text>
                  {account.debtPlanSeedBalanceAmountCents
                    ? formatMoney({
                        amountCents: account.debtPlanSeedBalanceAmountCents,
                      })
                    : EMPTY_VALUE_PLACEHOLDER}
                </Text>
              </Description.Item>
              <Description.Item label="Debt Plan Seed Interest Rate">
                <Text>
                  {account.debtPlanSeedInterestRate
                    ? formatPercent({
                        value: account.debtPlanSeedInterestRate,
                      })
                    : EMPTY_VALUE_PLACEHOLDER}
                </Text>
              </Description.Item>
              <Description.Item label="Debt Plan Seed Minimum Payment">
                <Text>
                  {account.debtPlanSeedMinimumPaymentAmountCents
                    ? formatMoney({
                        amountCents: account.debtPlanSeedMinimumPaymentAmountCents,
                      })
                    : EMPTY_VALUE_PLACEHOLDER}
                </Text>
              </Description.Item>
              <Description.Item label="Debt Plan Seed Interest Rate Estimated">
                <Text>{account.debtPlanSeedInterestRateIsEstimated ? "True" : "False"}</Text>
              </Description.Item>
              <Description.Item label="Debt Plan Seed Minimum Payment Estimated">
                <Text>{account.debtPlanSeedMinimumPaymentAmountCentsIsEstimated ? "True" : "False"}</Text>
              </Description.Item>
            </Description>
          </Col>
          <Col>
            <Description>
              <Description.Item label="Data Source Manual">
                <Text>{account.isDataSourceManual ? "True" : "False"}</Text>
              </Description.Item>
              <Description.Item label="Connectivity Data Source">
                <Text>{formatEnumValue(account.connectivityDataSource)}</Text>
              </Description.Item>
              <Description.Item label="Connectivity Data Source Allowed Transitions">
                <Text>
                  {i18n.toSentence(account.connectivityDataSourceAllowedTransitions.map(e => formatEnumValue(e)))}
                </Text>
              </Description.Item>
              <Description.Item label="Connectivity Can Receive Payment">
                <Text>{account.connectivityCanReceivePayments ? "True" : "False"}</Text>
              </Description.Item>
            </Description>
            <AggregatorDataUpdatesIgnoredSwitch
              account={account}
              onAccountChange={updatedAccount => dispatch({ type: "set", data: updatedAccount })}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col>
            <Description>
              <Description.Item label="Created At">
                <DateDisplay numberOfLines={1} value={account.createdAt} showTime />
              </Description.Item>
              <Description.Item label="Updated At">
                <DateDisplay numberOfLines={1} value={account.updatedAt} showTime />
              </Description.Item>
            </Description>
          </Col>
        </Row>
      </Collapse>
    </>
  );
};

const getAccountStatusTag = (accountStatus: FinancialAccountOutputAccountStatusEnum) => {
  switch (accountStatus) {
    case FinancialAccountOutputAccountStatusEnum.Closed:
    case FinancialAccountOutputAccountStatusEnum.Disabled:
      return <Tag value={accountStatus} color="red" />;
    case FinancialAccountOutputAccountStatusEnum.Live:
      return <Tag value={accountStatus} color="green" icon={SuccessIcon} />;
    default:
      return <Tag value={accountStatus} color="blue" icon={MoreDotsIcon} />;
  }
};

export default GeneralInfoCard;
