import { AuditLogOutput } from "@budgeinc/budge-api";
import { TColumnProps, Text, Tag, EMPTY_VALUE_PLACEHOLDER } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";

export const getAuditsColumns = (): TColumnProps<AuditLogOutput>[] => [
  {
    id: "type",
    title: "Type",
    maxWidth: 200,
    render: record => (
      <Tag
        maw="100%"
        color="dark"
        value={
          <Text numberOfLines={1} f={1}>
            {record.type}
          </Text>
        }
      />
    ),
  },
  {
    id: "description",
    title: "Description",
    render: record => (
      <Text numberOfLines={1} pr="xl">
        {record.description}
      </Text>
    ),
  },
  {
    id: "impersonator",
    title: "Impersonator",
    render: record => {
      if (record.impersonatorUser) {
        return (
          <Text>
            {record.impersonatorUser.firstName} {record.impersonatorUser.lastName}
          </Text>
        );
      }

      return <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>;
    },
  },
  {
    id: "createdat",
    title: "Created at",
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} showTime />,
  },
];
