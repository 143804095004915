import React from "react";
import { Button, errorManager, TButtonProps, toast } from "@budgeinc/budge-ui-core";
import { useBudgeMutation } from "api/useBudgeMutation";
import { usersApi } from "api/BudgeApi";
import { EmployeeOutput, ErrorResponse } from "@budgeinc/budge-api";
import { AxiosError } from "axios";

const ReEngageCustomerButton = ({
  onPress,
  employee,
  ...props
}: TButtonProps & {
  employee: EmployeeOutput;
}) => {
  const reEngageCustomer = useBudgeMutation({
    mutationFn: async (email: string) => {
      const response = await usersApi.initiateLoginUsingLink({
        isSelf: false,
        email,
      });
      return response.data;
    },
    onSuccess: () => {
      toast.success({
        message: "Re-engage email sent successfully",
      });
    },
    onError: (error: AxiosError<ErrorResponse>) =>
      errorManager.showError({
        error: error.response?.data,
      }),
  });

  return (
    <Button
      title="Re-engage"
      size="xs"
      color="primary"
      variant="gradient"
      loading={reEngageCustomer.isPending}
      onPress={e => {
        onPress?.(e);
        reEngageCustomer.mutate(employee.email);
      }}
      {...props}
    />
  );
};

export default ReEngageCustomerButton;
