/* eslint-disable react/no-unstable-nested-components */
import { Box, AccessScopeValidator, TAccessOperation, InfiniteScrollTableV2 } from "@budgeinc/budge-ui-core";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import React, { useCallback, useMemo, useReducer } from "react";
import { employeesCrossTenantApi } from "api/BudgeApi";
import {
  EmployeeSearchCriteriaInput,
  EmployeeWithUserAndSubscriptionsOutput,
  UserWhoamiOutputScopesEnum,
} from "@budgeinc/budge-api";
import EmployeeStatusInfoContent from "features/employees/tabs/EmployeeDetails/components/EmployeeStatusInfoContent";
import useTableSorting from "hooks/useTableSorting";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import { useBudgeInfiniteQuery } from "api/useBudgeInfiniteQuery";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";
import { getInitialFilters, hasFilters } from "components/ListFilters/utils";
import { initListFilters, ListFiltersContext, TListFilterContextType } from "components/ListFilters/context";
import { getEmployeeColumns } from "./columns";
import { EmployeeSearchContext } from "./context";
import { employeesReducer, initialState } from "./reducer";
import BatchUpdateButton from "./components/BatchUpdateButton";
import ExportEmployeesButton from "./components/ExportEmployeesButton";
import { getCustomersFiltersConfig } from "./filters";
import InviteCustomerButton from "./components/InviteCustomerButton";

const EmployeeListPersistSortId = "employee-list-sort";

const filtersConfig = getCustomersFiltersConfig("employee-list-filters");
const { ListFiltersReducer } = initListFilters(filtersConfig);

const EmployeesListTab = () => {
  const {
    state: { employer, managers },
  } = useEmployerContext();
  const [state, dispatch] = useReducer(employeesReducer, initialState);
  const { sort, updateSort, sortRefreshIndex } = useTableSorting(EmployeeListPersistSortId);
  const [filters, filterDispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      employeesCrossTenantApi
        .searchEmployees(
          employer?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString(),
          sort?.columnId,
          sort?.direction
        )
        .then(({ data }) => data),
    [JSON.stringify(filters), sortRefreshIndex]
  );

  const { data, fetchNextPage, hasNextPage, isDataLoading, isFetchingNextPage, refetch } = useBudgeInfiniteQuery({
    queryKey: ["tenant-customers-list", filters, sortRefreshIndex],
    queryFn: ({ pageParam }) => handleOnFetch({ page: pageParam, pageSize: 20 }),
    pageSize: 20,
    staleTime: 10 * 60 * 1000,
  });

  const customers = useMemo(
    () => data?.pages.reduce((acc, page) => [...acc, ...page], [] as EmployeeWithUserAndSubscriptionsOutput[]) || [],
    [data]
  );

  const filterMemoedContextValues = useMemo<TListFilterContextType<EmployeeSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch: filterDispatch,
    }),
    [filters, filterDispatch]
  );

  const memoedContextValues = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return (
    <ListFiltersContext.Provider value={filterMemoedContextValues}>
      <EmployeeSearchContext.Provider value={memoedContextValues}>
        <Box f={1}>
          <ListFiltersBar
            filtersConfig={filtersConfig}
            extra={
              <>
                <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.ClientsInvite]}>
                  <InviteCustomerButton />
                </AccessScopeValidator>
                <AccessScopeValidator op={TAccessOperation.WRITE}>
                  <BatchUpdateButton onComplete={refetch} />
                </AccessScopeValidator>
                <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.UserAccess]}>
                  <ExportEmployeesButton />
                </ExportAccessScopeValidator>
              </>
            }
          />
          <InfiniteScrollTableV2<EmployeeWithUserAndSubscriptionsOutput>
            f={1}
            data={customers}
            sort={sort}
            onSort={updateSort}
            columns={getEmployeeColumns(employer!, managers)}
            keyExtractor={item => item.id}
            loading={isDataLoading}
            loadingNextPage={isFetchingNextPage}
            reachedEnd={!hasNextPage}
            onEndReached={() => fetchNextPage()}
            onEndReachedThreshold={0.5}
            local={{
              empty: {
                m: 24,
                title: "No customer found",
                description: hasFilters(filterMemoedContextValues)
                  ? "No customer match these filters. Edit or clear all filters."
                  : undefined,
              },
              noMoreItems: "No more customer to load",
            }}
            contentContainerStyle={{ marginBottom: 16 }}
            expandable={record => (
              <EmployeeStatusInfoContent
                record={{
                  ...record,
                  subscription: record.subscriptions.length ? record.subscriptions[0] : undefined,
                }}
              />
            )}
          />
        </Box>
      </EmployeeSearchContext.Provider>
    </ListFiltersContext.Provider>
  );
};

export default EmployeesListTab;
