import { ErrorResponse } from "@budgeinc/budge-api";
import { getApiErrorMessage } from "@budgeinc/budge-ui-core";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { globalSelector } from "store/global/selector";

export const useBudgeMutation = <
  TData = unknown,
  TError = AxiosError<ErrorResponse>,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>
) => {
  const { messages } = useSelector(globalSelector);
  const useMutationResult = useMutation<TData, TError, TVariables, TContext>(options);

  const errorMessage = useMemo(() => {
    if (useMutationResult.error == null) return "";

    let error = useMutationResult.error as any;

    if (error?.apiError != null) {
      error = error.apiError;
    }

    return getApiErrorMessage(messages, (error as AxiosError<ErrorResponse>)?.response?.data);
  }, [useMutationResult.error]);

  return {
    ...useMutationResult,
    errorMessage,
  };
};
