import React, { useEffect } from "react";
import { Button, errorManager, TButtonProps, toast } from "@budgeinc/budge-ui-core";
import { employeesCrossTenantApi } from "api/BudgeApi";
import { EmployeeOutput, EmployerOutput } from "@budgeinc/budge-api";
import { useBudgeQuery } from "api/useBudgeQuery";

const ImpersonateCustomerButton = ({
  onPress,
  employee,
  employer,
  ...props
}: TButtonProps & {
  employee: EmployeeOutput;
  employer: EmployerOutput;
}) => {
  const impersonationLoginUrl = useBudgeQuery({
    queryKey: [employer.id, employee.id],
    queryFn: async () => {
      const response = await employeesCrossTenantApi.getImpersonationLoginUrl(employer.id, employee.id);
      return response.data;
    },
    enabled: false,
  });

  useEffect(() => {
    if (impersonationLoginUrl.errorMessage) {
      errorManager.showError({
        error: undefined,
        customMessage: impersonationLoginUrl.errorMessage,
      });
    }
  }, [impersonationLoginUrl.errorMessage]);

  return (
    <Button
      title="Impersonate"
      size="xs"
      color="primary"
      variant="gradient"
      loading={impersonationLoginUrl.isFetching}
      onPress={e => {
        onPress?.(e);
        impersonationLoginUrl.refetch().then(({ data }) => {
          if (data?.impersonationLoginUrl) {
            window.open(data?.impersonationLoginUrl);
          } else {
            toast.error({
              message: "Unable to open impersonation login url",
            });
          }
        });
      }}
      {...props}
    />
  );
};

export default ImpersonateCustomerButton;
