import {
  EmployeeOutputFunnelStageEnum,
  EmployeeWithUserAndSubscriptionsOutput,
  EmployerOutput,
  ManagerOutput,
} from "@budgeinc/budge-api";
import {
  Stack,
  TColumnProps,
  Text,
  EMPTY_VALUE_PLACEHOLDER,
  formatEmployeeName,
  DeleteIcon,
  SuccessIcon,
  Tag,
  i18n,
} from "@budgeinc/budge-ui-core";

import DateDisplay from "components/DateDisplay";
import ActionCell from "./components/ActionCell";

export const getEmployeeColumns = (
  employer: EmployerOutput,
  managers: ManagerOutput[]
): TColumnProps<EmployeeWithUserAndSubscriptionsOutput>[] => [
  {
    id: "firstName",
    title: "Name",
    maxWidth: 200,
    sortable: true,
    render: employee => {
      const isManager = managers.find(({ userId }) => userId === employee.userId);

      return (
        <Stack.Horizontal alignItems="center" fwrap="wrap">
          <Text numberOfLines={2}>{formatEmployeeName({ employee })}</Text>
          {isManager && <Tag size="xs" color="primary" value="Manager" />}
        </Stack.Horizontal>
      );
    },
  },
  {
    id: "email",
    title: "Email",
    width: 125,
    sortable: true,
    render: record => <Text numberOfLines={2}>{record.email || EMPTY_VALUE_PLACEHOLDER}</Text>,
  },
  {
    id: "status",
    title: "Funnel Stage",
    width: 200,
    render: ({ funnelStage }) => {
      const isCancelled =
        funnelStage === EmployeeOutputFunnelStageEnum.BudgeAccountCanceledViaStripe ||
        funnelStage === EmployeeOutputFunnelStageEnum.BudgeAccountTerminated;

      return (
        <Tag
          value={i18n.get(`enums.funnelStage.${funnelStage}`)}
          color={isCancelled ? "red" : "green"}
          icon={isCancelled ? DeleteIcon : SuccessIcon}
        />
      );
    },
  },
  {
    id: "utmCampaign",
    title: "UTM Campaign",
    maxWidth: 175,
    sortable: true,
    render: record => <Text numberOfLines={2}>{record.utmCampaign || EMPTY_VALUE_PLACEHOLDER}</Text>,
  },
  {
    id: "createdAt",
    title: "Created at",
    sortable: true,
    maxWidth: 175,
    render: ({ createdAt }) => <DateDisplay numberOfLines={1} value={createdAt} showTime />,
  },
  {
    title: "",
    id: "action",
    align: "flex-end",
    width: 300,
    maxWidth: 300,
    render: record => <ActionCell employer={employer} employee={record} />,
  },
];
