import { useLocation } from "react-router-dom";
import { Tabs, TAccessOperation, useAccessScopeContext, useAccessScopeValidator } from "@budgeinc/budge-ui-core";
import ContentHeader from "components/Layout/Main/Header";
import EmployeesListTab from "features/employers/entity/tabs/Employees";
import EmployerDetailTab from "features/employers/entity/tabs/EmployerDetails";
import ProgramsListTab from "features/employers/entity/tabs/Programs";
import FinancialsTab from "features/employers/entity/tabs/Financials";
import InvitesListTab from "features/employers/entity/tabs/Invites";
import SubscriptionsListTab from "features/employers/entity/tabs/Subscriptions";
import ReferralPaymentsTab from "features/employers/entity/tabs/ReferralPayments";
import useEmployerEntity from "features/employers/entity/hooks/useEmployerEntity";
import { EmployerEntityContext } from "features/employers/entity/contexts/EmployerEntityContext";
import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import CenteredSpinner from "components/CenteredSpinner";
import withAccessScopeValidator from "components/AccessScopeValidator/withAccessScopeValidator";
import SentimentPollsListTab from "features/employers/entity/tabs/SentimentPolls";

export enum EMPLOYER_TAB_KEYS {
  DETAILS = "#details",
  EMPLOYEES = "#employees",
  EMAIL_VERIFICATIONS = "#email-verifications",
  FINANCIALS = "#financials",
  PAYROLLS = "#payrolls",
  CAMPAIGNS = "#campaigns",
  PROGRAMS = "#programs",
  REPRESENTATIVES = "#representatives",
  SUBSCRIPTIONS = "#subscriptions",
  SENTIMENT_POLLS = "#sentiment-polls",
  REFERRALS = "#referrals",
}

export const Component = withAccessScopeValidator(() => {
  const { hash } = useLocation();
  const { scopes } = useAccessScopeContext();
  const { validateAccessScopes } = useAccessScopeValidator();
  const { isLoading, employer, memoedContextValues } = useEmployerEntity();

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <EmployerEntityContext.Provider value={memoedContextValues}>
      <ContentHeader title={employer?.name} />
      <Tabs
        onChange={activeKey => {
          window.location.hash = activeKey;
        }}
        defaultActiveKey={hash || EMPLOYER_TAB_KEYS.DETAILS}
        activeKey={hash}
      >
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.Tenants]) && (
          <Tabs.Pane title="Details" tabKey={EMPLOYER_TAB_KEYS.DETAILS} flex={false}>
            <EmployerDetailTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.Clients]) && (
          <Tabs.Pane title="Customers" tabKey={EMPLOYER_TAB_KEYS.EMPLOYEES}>
            <EmployeesListTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.Security]) && (
          <Tabs.Pane title="Email Verifications" tabKey={EMPLOYER_TAB_KEYS.EMAIL_VERIFICATIONS}>
            <InvitesListTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.ClientsFinancials]) && (
          <Tabs.Pane title="Programs" tabKey={EMPLOYER_TAB_KEYS.PROGRAMS}>
            <ProgramsListTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.ClientsFinancials]) && (
          <Tabs.Pane title="Financials" tabKey={EMPLOYER_TAB_KEYS.FINANCIALS}>
            <FinancialsTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.Subscriptions]) && (
          <Tabs.Pane title="Subscriptions" tabKey={EMPLOYER_TAB_KEYS.SUBSCRIPTIONS}>
            <SubscriptionsListTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.ClientsSentiments]) && (
          <Tabs.Pane title="Sentiments" tabKey={EMPLOYER_TAB_KEYS.SENTIMENT_POLLS}>
            <SentimentPollsListTab />
          </Tabs.Pane>
        )}
        {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.Referrals]) && (
          <Tabs.Pane title="Referral Payments" tabKey={EMPLOYER_TAB_KEYS.REFERRALS}>
            <ReferralPaymentsTab />
          </Tabs.Pane>
        )}
      </Tabs>
    </EmployerEntityContext.Provider>
  );
}, [
  {
    OR: [UserWhoamiOutputScopesEnum.Admin, UserWhoamiOutputScopesEnum.AdminRead, UserWhoamiOutputScopesEnum.Tenants],
  },
]);
